import React, { useState } from 'react';
import {
    Button,
    TextField,
    Typography,
    Container,
    Grid2,
} from '@mui/material';
import { db, storage } from './Firebase'; // Import storage
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { uuidv4 } from '@firebase/util'; // For generating unique file names

const AddGift = () => {
    const [giftName, setGiftName] = useState('');
    const [giftDescription, setGiftDescription] = useState('');
    const [image, setImage] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!image) {
            alert('Please select an image.');
            return;
        }
    
        const storageRef = ref(storage, `gift-images/${uuidv4()}-${image.name}`); // Unique filenames
    
        try {
            // Upload the image file
            const uploadResult = await uploadBytesResumable(storageRef, image);
            const downloadURL = await getDownloadURL(uploadResult.ref); // Get the download URL
            console.log(uploadResult.ref)
            await addDoc(collection(db, "gifts"), {
                name: giftName,
                description: giftDescription,
                image: downloadURL, // Store the download URL
                reservedBy: null,
            });
    
            alert("Gift added successfully!");
            setGiftName('');
            setGiftDescription('');
            setImage(null);
            setUploadProgress(0); // Reset progress

        } catch (error) {
            console.error('Error adding gift:', error);
            alert('There was a problem uploading. Try again');
        }

    };


    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Add New Gift
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid2 container spacing={2}>
                    <Grid2 item xs={12}>
                        <TextField
                            label="Gift Name"
                            variant="outlined"
                            fullWidth
                            value={giftName}
                            onChange={(e) => setGiftName(e.target.value)}
                            required
                        />
                    </Grid2>
                    <Grid2 item xs={12}>
                        <TextField
                            label="Gift Description"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            value={giftDescription}
                            onChange={(e) => setGiftDescription(e.target.value)}
                        />
                    </Grid2>
                    <Grid2 item xs={12}>
                        <input type="file" onChange={handleImageChange} accept="image/*" />
                         {uploadProgress > 0 && <Typography variant="body2">{uploadProgress}% uploaded</Typography>}
                    </Grid2>
                    <Grid2 item xs={12}>
                        <Button variant="contained" color="secondary" type="submit">
                            Add Gift
                        </Button>
                    </Grid2>
                </Grid2>
            </form>
        </Container>
    );
};

export default AddGift;