import React, { useState, useEffect } from 'react';
import { auth, db } from './Firebase';
import 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Button, Container, FormControlLabel, Radio, Typography, TextField, Grid2, Alert } from '@mui/material';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

const Trip = () => {
    const [userData, setUserData] = useState(null);
    const [isAttending, setIsAttending] = useState(null);
    const [avecName, setAvecName] = useState('');
    const [allergies, setAllergies] = useState('');
    const [email, setEmail] = useState('');
    const { code } = useParams();
    const [showAlert, setShowAlert] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {

        const fetchData = async () => {
            try {
                const usersSnapshot = await db.collection('invites').get();
                let foundUser = null;

                usersSnapshot.forEach(doc => {
                    if (doc.id.slice(-4) === code) {
                        foundUser = doc.data();
                    }
                });
                if (!foundUser) {
                    console.log('New user coming in')
                } else {
                    setUserData(foundUser);
                }
            } catch (error) {
                console.error('Error fetching or creating user:', error);
            }
        };

        fetchData();
    }, [code, email]);

    const handleRSVPChange = (event) => {
        setIsAttending(event.target.value);
    };

    const handleAvecNameChange = (event) => {
        setAvecName(event.target.value);
    };

    const handleAllergiesChange = (event) => {
        setAllergies(event.target.value);
    };

    const submitRSVP = async () => {
        if (isAttending === null || !email) {
            setShowAlert({ open: true, message: 'Täytäthän kaikki tarvittavat kohdat.', severity: 'error' }); // Show error alert
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, 'defaultPassword');
            const userId = userCredential.user.uid;

            const newUser = {
                email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                avec: userData.avec
            };
            await db.collection('users').doc(userId).set(newUser);
            await sendPasswordResetEmail(auth, email)
                .then(() => {
                    console.log('Password email sent successfully.');
                })
                .catch((error) => {
                    console.error('Error sending password reset email:', error);

                });

            const response = {
                userId,
                firstName: userData.firstName,
                lastName: userData.lastName,
                avecName,
                allergies,
                attending: isAttending,
            };

            await db.collection('responses').add(response);
            setShowAlert({ open: true, message: 'Kiitos vastauksestasi!', severity: 'success' });
        } catch (error) {
            console.error('Error creating user or submitting RSVP:', error);
            setShowAlert({ open: true, message: 'Vastauksen lähettäminen epäonnistui. Yritä kohta uudelleen tai ota yhteyttä!', severity: 'error' });
        }
    };


    return (
        <Container>
            <>
                {showAlert.open && (
                    <Alert onClose={() => setShowAlert({ ...showAlert, open: false })} severity={showAlert.severity}>
                        {showAlert.message}
                    </Alert>
                )}
                <div className="wedding-rsvp">
                    {userData ? (
                        <>
                            <Typography variant="h3" component="h3">
                                Tervetuloa matkalle kanssamme!
                            </Typography>
                            <br />
                            <Typography paragraph>
                                Dear {userData.firstName} {userData.lastName} {userData.avec ? (<> ja {userData.avecName ? userData.avecName : <>avec</>}</>) : (<>sinut </>)},
                            </Typography>
                            <Typography paragraph>
                                Kutsumme {userData.avec ? (<>teidät </>) : (<>sinut </>)}
                                mukaan meidän yhteiselle matkalle 24.5.2025 Vanhasta Tuomiokirkosta Scandic Hotel Grand Centraliin häidemme merkeissä.
                            </Typography>
                            <Typography paragraph>
                                Varaathan matkasi viimeistään 1.3.2025.
                            </Typography>
                            <Typography paragraph>
                                Pääasiassa juhlat juhlitaan aikuisten kesken, mutta lähipiirissämme on pieniä lapsia joita ei voi jättää kotiin tai hoitoon. Myös Tuomaksen lapset ovat mukana juhlassa.
                                Jos teidän tarvitsee ottaa lapsi/lapset mukaan, niin otattehan meihin yhteyttä asian tiimoilta, niin tiedämme varautua asiaan.
                            </Typography>
                            <Typography paragraph>
                                RSVP:n jälkeen saat sähköpostia jolla pääset tälle sivulle vielä takaisinkin, mikäli tarvitsee saada myöhemmin lisätietoa.
                            </Typography>
                            <br />
                            <Grid2 container spacing={2}>
                                <Grid2 item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                id="attending-yes"
                                                name="attending"
                                                value="yes"
                                                onChange={handleRSVPChange}
                                                checked={isAttending === 'yes'}
                                            />
                                        }
                                        label="Kyllä, osallistun"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                id="attending-no"
                                                name="attending"
                                                value="no"
                                                onChange={handleRSVPChange}
                                                checked={isAttending === 'no'}
                                            />
                                        }
                                        label="En pääse osallistumaan"
                                    />
                                </Grid2>
                            </Grid2>
                            <Grid2 container spacing={2}>
                                {isAttending === 'yes' && (
                                    <>
                                        <Grid2 item xs={12}>
                                            <TextField
                                                id="email"
                                                label="Sähköposti"
                                                type="email"
                                                fullWidth
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Grid2>
                                        {userData.avec === true && (
                                            <Grid2 item xs={12}>
                                                <TextField
                                                    id="avec-name"
                                                    label="Avecin nimi"
                                                    fullWidth
                                                    value={avecName}
                                                    onChange={handleAvecNameChange}
                                                />
                                            </Grid2>
                                        )}
                                        <Grid2 item xs={12}>
                                            <TextField
                                                id="allergies"
                                                label="Allergiat ja ruokarajoitteet"
                                                multiline
                                                fullWidth
                                                value={allergies}
                                                onChange={handleAllergiesChange}
                                            />
                                        </Grid2>
                                    </>
                                )}
                                {isAttending === 'no' && (
                                    <>
                                        <br />
                                        <Typography paragraph>Harmi ettet pääse. yhyy :(</Typography>
                                        {/* <p> Haluatko osallistua kuitenkin häärahastoon? Se onnistuu helposti esimerkiksi <Link to="https://qr.mobilepay.fi/box/220d21f3-6539-4f57-b672-feaadaa9670b/pay-in">MobilePayllä</Link>!</p>
                                        <p>Häärahasto on menossa lyhentämättömänä juhliin ja jos jotain jää, niin häämatkaan.</p> */}
                                    </>
                                )}
                            </Grid2>
                            <br />
                            <Button variant="contained" color="secondary" fullWidth onClick={submitRSVP} disabled={isAttending === null}>
                                Lähetä vastaus
                            </Button>
                        </>
                    ) : (
                        <p>Lataillaan...</p>
                    )}
                </div>
            </>
        </Container >
    );
};

export default Trip;