import { Container, Typography, Grid2, Link } from '@mui/material';
import './Footer.css'

const Footer = () => {
  return (
    <footer className='footer' style={{ backgroundColor: 'black', padding: '20px 0' }}>
      <Container>
        <Grid2 container spacing={4}>
          <Grid2 item xs={12} md={3}>
            <Typography variant="h6" color="secondary">Info</Typography>
            <Link href="/saapuminen">Saapuminen</Link>
            <br />
            <Link href="/majoitus">Majoitus</Link>
          </Grid2>
          <Grid2 item xs={12} md={3}>
            <Typography variant="h6" color="secondary">&nbsp;</Typography>
            <Link href="/aikataulu">Juhlan kulku</Link>
            <br />
            <Link href="/lahjalista">Lahjalista</Link>
          </Grid2>
          <Grid2 item xs={12} md={3}>
            <Typography variant="h6" color="secondary">&nbsp;</Typography>
            <Link href="/majoitus">Ota yhteyttä</Link>
          </Grid2>
        </Grid2>
      </Container>
    </footer>
  );
};

export default Footer;