import React, { useState, useEffect } from 'react';
import { db } from './Firebase';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import {
    Button,
    TextField,
    Typography,
    Container,
    Grid2,
    FormControlLabel,
    Checkbox,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';

const InviteList = () => {
    const [invites, setInvites] = useState([]);
    const [newInvite, setNewInvite] = useState({
        firstName: '',
        lastName: '',
        avec: false,
    });

    useEffect(() => {
        const fetchInvites = async () => {
            const inviteCollectionRef = collection(db, 'invites');
            const inviteSnapshot = await getDocs(inviteCollectionRef);
            const inviteList = inviteSnapshot.docs.map(doc => ({
                id: doc.id,
                code: doc.id.slice(-4),
                ...doc.data()
            }));
            setInvites(inviteList);
        };
        fetchInvites();
    }, []);

    const handleChange = (e) => {
        setNewInvite({ ...newInvite, [e.target.name]: e.target.value });
    };

    const handleAvecChange = (e) => {
        setNewInvite({ ...newInvite, avec: e.target.checked });
    };

    const handleAddInvite = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        const inviteCollectionRef = collection(db, 'invites');

        try {
            await addDoc(inviteCollectionRef, newInvite);
            alert('Invite added successfully!');
            setNewInvite({ firstName: '', lastName: '', avec: false });
        } catch (error) {
            console.error('Error adding invite:', error);
            alert('Error adding invite. Please try again.');
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Kutsut
            </Typography>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Koodi</TableCell>
                        <TableCell>Etunimi</TableCell>
                        <TableCell>Sukunimi</TableCell>
                        <TableCell>Avec</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invites.map(invite => (
                        <TableRow key={invite.id}>
                            <TableCell>{invite.code}</TableCell>
                            <TableCell>{invite.firstName}</TableCell>
                            <TableCell>{invite.lastName}</TableCell>
                            <TableCell>{invite.avec ? 'On' : 'Ei'}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <Typography variant="h6" gutterBottom style={{ marginTop: '2rem' }}>
                Lisää uusi kutsu
            </Typography>

            <form onSubmit={handleAddInvite}>
                <Grid2 container spacing={2}>
                    <Grid2 item xs={12} sm={6}>
                        <TextField
                            label="Etunimi"
                            name="firstName"
                            fullWidth
                            value={newInvite.firstName}
                            onChange={handleChange}
                        />
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                        <TextField
                            label="Sukunimi"
                            name="lastName"
                            fullWidth
                            value={newInvite.lastName}
                            onChange={handleChange}
                        />
                    </Grid2>
                    <Grid2 item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="avec"
                                    checked={newInvite.avec}
                                    onChange={handleAvecChange}
                                />
                            }
                            label="Avec"
                        />
                    </Grid2>
                    <Grid2 item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Lisää kutsu
                        </Button>
                    </Grid2>
                </Grid2>
            </form>
        </Container>
    );
};

export default InviteList;