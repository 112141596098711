import { Typography, Container, Grid2 } from '@mui/material';

const Accomodation = () => {
  return (
    <Container>
      <Typography variant="h3" component="h1" gutterBottom>
        Majoitus
      </Typography>

      <Grid2 container spacing={2}>
        <Grid2 item xs={12}>
          <Typography variant="h5" gutterBottom>
            Scandic Grand Central
          </Typography>
          <Typography paragraph>
           Voit majoittua samassa hotellissa, jossa juhlat pidetään. Scandicin sivuilta saat alennuskoodilla EVNT varattua huoneen kampanjahintaan. Tällöin juhlapaikalta ei tarvitse illan päättyessä siirtyä kuin portaat ylös.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography variant="h5" gutterBottom>
            Airbnb
          </Typography>
          <Typography paragraph>
            Voit myös etsiä majoitusta Airbnb:stä. Läheltä hääpaikkaa löytyy useita viihtyisiä asuntoja.
          </Typography>

        </Grid2>
      </Grid2>
    </Container>
  );
};

export default Accomodation;