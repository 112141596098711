import { Typography, Container, Grid2 } from '@mui/material';

const Timetable = () => {
  return (
    <Container>
      <Typography variant="h3" component="h1" gutterBottom>
        Juhlan kulku
      </Typography>
      <br />

      <Grid2 container spacing={2}>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Vihkiminen alkaa klo 16 Helsingin Vanhassa kirkossa. Vihkimisen jälkeen voit siirtyä esimerkiksi kävellen tai julkisilla juhlapaikalle Scandic Grand Centralille.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Juhlapaikalla juhlat alkavat klo 17 ja maljat nostetaan n. klo 17.30.
            Tämän jälkeen ilta jatkuu ruokailulla ja ohjelmalla. Ruokailuun kuuluu alkupalat, pääruoka ja kakkua ja kahvia jälkiruoaksi. Alkumaljan lisäksi tarjoamme kaksi kaatoa viiniä ruoan kanssa. Jos haluat nauttia enemmänkin juomia, omakustanteinen baari palvelee koko juhlan ajan.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Noin klo 21 päästetään bändi lauteille. Bändi soittaa klo 24 asti taukojen kera. Toivomme, että lapset lähtisivät klo 22 mennessä juhlasta, jonka jälkeen juhlat jatkuvat aikuisten kesken. Keskiyöllä bändi vaihtuu DJksi ja pilkku tulee klo 02.30.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Iltaohjelmassa on lisäksi mahdollisuus laulaa karaokea, jättää tervehdyksiä, kuvauttaa itsensä kuvausseinällä ja katsoa jääkiekon MM-ottelua.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Ruokailun lomassa kuullaan myös puheita.
            Jos haluat pitää puheen, otathan yhteyttä Piaan tai Tuomakseen.
          </Typography>
          <Typography paragraph>
            Pukukoodi: häihin sopiva juhlava asu.
          </Typography>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default Timetable;