import { useState, useEffect } from 'react';
import { Typography, Container, Grid2, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { db, auth } from './Firebase';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';

const Giftlist = () => {
  const [gifts, setGifts] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(authUser => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);


  useEffect(() => {
    const fetchGifts = async () => {
      const giftsCollectionRef = collection(db, 'gifts');
      const giftsSnapshot = await getDocs(giftsCollectionRef);
      const giftsList = giftsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setGifts(giftsList);
    };

    fetchGifts();
  }, []);

  const handleGiftClaim = async (giftId) => {
    if (!user) {
      alert("Kirjaudu sisään varataksesi lahjan!"); // Or redirect to login
      return;
    }

    try {
      const giftRef = doc(db, 'gifts', giftId);
      await updateDoc(giftRef, { reservedBy: user.uid });

      setGifts(gifts.map(gift =>
        gift.id === giftId ? { ...gift, reservedBy: user.uid } : gift
      ));

    } catch (error) {
      console.error("Error claiming gift:", error);
      alert("Lahjan varaaminen epäonnistui. Yritä uudelleen.");
    }
  }

  const handleGiftRelease = async (giftId) => {
    try {
      const giftRef = doc(db, 'gifts', giftId);
      await updateDoc(giftRef, { reservedBy: null }); // Set reservedBy to null

      setGifts(gifts.map(gift =>
        gift.id === giftId ? { ...gift, reservedBy: null } : gift
      ));
    } catch (error) {
      console.error("Error releasing gift:", error);
      alert("Lahjan vapauttaminen epäonnistui. Yritä uudelleen.");
    }
  };

  return (
    <Container>
      <Typography variant="h3" component="h1" gutterBottom>
        Lahjalista
      </Typography>
      <br />

      <Grid2 container spacing={2}>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Osallistumisenne tärkeään juhlaamme on lahjoista parhain.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Jos lisäksi haluatte muistaa meitä jotenkin, niin fyysiset, aineettomat, itsetehdyt, rahallisetkin ym. lahjat ovat kaikki tervetulleita. Voitte myös yhdistää voimanne.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Täältä/tästä löydätte lahjalistamme, jonne olemme pohtineet meille tarpeellisia ja toivottuja asioita.
          </Typography>
        </Grid2>
        <Grid2 item xs={12}>
          <Typography paragraph>
            Voitte myös halutessanne olla mukana kerryttämässä häämatkakassaamme <Link to="https://qr.mobilepay.fi/box/220d21f3-6539-4f57-b672-feaadaa9670b/pay-in">MobilePayllä</Link> tai perinteisellä tilisiirrolla.
          </Typography>
        </Grid2>
      </Grid2>
      <Grid2 item xs={12}>
        <Typography paragraph>
          Lahjalistassa ajatuksena on se että estetään tuplalahjat sillä että valitsette mitä tuotte. Emme yllätyksen pilaamisen estämiseksi ennen tapahtumaa näe valintoja.
        </Typography>
      </Grid2>

      <Grid2 container spacing={2}>
        {gifts.map((gift) => (
          <Grid2 item xs={12} sm={6} md={4} key={gift.id}>
            <Card>
              {gift.image && <CardMedia component="img" height="140" image={gift.image} alt={gift.name} />}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {gift.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {gift.description}
                </Typography>
                {gift.reservedBy ? (
                  <>
                    {gift.reservedBy === user.uid && (  // Conditionally render Release button
                      <Button variant="outlined" color="error" onClick={() => handleGiftRelease(gift.id)}>
                        Peruuta varaus
                      </Button>)}
                    {gift.reservedBy !== user.uid && (
                      <Typography variant="body2" color="error">
                        Varattu
                      </Typography>
                    )}

                  </>
                ) : (
                  <Button variant="contained" color="secondary" onClick={() => handleGiftClaim(gift.id)}>
                    Valitse
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};


export default Giftlist;